//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect, useRef}from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../../Section/AdminHeader';
import * as H from '../../../Styles/StyleComponentHan';
//에디터 사용을 위해 @tinymce/tinymce-react사용할 것임
//npm install@tinymce/tinymce-react으로 설치했음
//공식 홈페이지는 https://www.tiny.cloud/
import { Editor } from '@tinymce/tinymce-react';
//editor 데이터를 가져오고 업데이트하고
//사진을 저장하기 위한 Axios 호출 자세한건
//Axios.js 참고
import {
  get_editor_data,
  update_editor_data,
} from "../../../Axios";
import {
  editor_init_data,
  editor_init_data_mo,
} from "../../../Variable";


export function BusinessAreaManagement() {  
  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // editorRef 아래 <Editor 이거를  참고하기 위해 사용했으며
  // <Editor 가면 editorRef를 사용한것을 볼 수 있습니다.  
  const editorRef = useRef(null);

  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  const [htmlString, setHtmlString] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const [htmlStringMobile, setHtmlStringMobile] = useState("");
  const [initialValueMobile, setInitialValueMobile] = useState("");

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //이거는 데이터베이스에 있는 Editor내용을 가져오기 위한 Function이고 
    //'3'은 사업영역 에디터에 대한 구분 값이다.
    //자세한 내용은 src/Axios.js 파일 확인
    get_editor_data('3').then(result_data=>{
      //에디터 데이터를 setHtmlString을 활용하여 htmlString에 할당
      setHtmlString(result_data[0].editor_content);
      setInitialValue(result_data[0].editor_content);
      setHtmlStringMobile(result_data[0].editor_content_mobile);
      setInitialValueMobile(result_data[0].editor_content_mobile);
    })
  },[]);

  return (
    <H.admin_MainWrap>
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>서비스 메뉴 관리 - 사업 영역</p>
            <div>
              <H.admin_changeBtn
              //onClick event에 대한 내용은 src/Admin/Login.js 참고
              //클릭 시 에디터 내용 수정
              //'3'은 옵티멈윈도우필름 가격표 및 스펙 에디터에 대한 구분 값이다.
              onClick={()=>{
                update_editor_data(
                  '3',
                  htmlString.replace( /'/gi, '"'),
                  htmlStringMobile.replace( /'/gi, '"'),
                ).then(result=>{
                  alert('수정이 완료되었습니다.');
                  window.location.reload();
                })
              }}
              >수정</H.admin_changeBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>

        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.listInfoText>
              <p className="listInfoTextBold">사용 방법 안내</p>
              <p>1. 보안 관계로 ''(작은따옴표)는 사용할 수 없습니다.</p>
              <p>2. ''(작은따옴표)를 사용한 경우에는 자동으로 ""(큰따옴표)로 변경됩니다.</p>
              <p>3. 이미지 파일명은 반드시 영문명으로 지정해야 오류가 나지 않습니다.</p>
              <p>4. 서식 적용이 되지 않을 경우 "서식 - 서식지우기" 아이콘을 활용하여 서식을 초기화 한 후 작성해주세요.</p>
              <p>5. 이미지 등록 시 PC는 최대 가로길이 1200px로 맞춰야 이미지 잘림 없이 볼 수 있습니다.</p>
            </H.listInfoText>
            <H.admin_editorBoxTit>
              PC용 에디터
              <span>(스크린 최소 사이즈 기준 1200px)</span>
            </H.admin_editorBoxTit>
            <Editor
              //tiny를 쓰기위한 고유 apiKey 전송
              apiKey='2c69ik81krso3r31bw1hgusvsc73bbxoth4z4rbfvskj9p9y'
              //처음 실행 시 위 선언했던 useRef를 참고함
              onInit={(evt, editor) => editorRef.current = editor}
              //초기 HTML 적용
              initialValue={initialValue}
              //init은 Varibale.js에서 가져왔으며 폰트추가나 플러그인 툴바 등등 에디터에 관한
              //내용을 추가 시키고 있으면 저기서 추가하면 됨
              init={editor_init_data}
              onEditorChange={(newValue, editor) => {
                setHtmlString(newValue);
              }}
            />
            <H.admin_editorBoxTit>
              모바일용 에디터
              <span>(스크린 최소 사이즈 기준 480px)</span>
            </H.admin_editorBoxTit>
            <Editor
              //tiny를 쓰기위한 고유 apiKey 전송
              apiKey='2c69ik81krso3r31bw1hgusvsc73bbxoth4z4rbfvskj9p9y'
              //처음 실행 시 위 선언했던 useRef를 참고함
              onInit={(evt, editor) => editorRef.current = editor}
              //초기 HTML 적용
              initialValue={initialValueMobile}
              //init은 Varibale.js에서 가져왔으며 폰트추가나 플러그인 툴바 등등 에디터에 관한
              //내용을 추가 시키고 있으면 저기서 추가하면 됨
              init={editor_init_data_mo}
              onEditorChange={(newValue, editor) => {
                setHtmlStringMobile(newValue);
              }}
            />

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default BusinessAreaManagement;
