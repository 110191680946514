//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect, useRef} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
import {
  insert_notice,
  get_notice_one,
  update_notice,
} from "../../Axios";

import * as H from '../../Styles/StyleComponentHan';

import { Editor } from '@tinymce/tinymce-react';

import {
  editor_init_data,
} from "../../Variable";

export function NoticeManagementAdd() {  
  //파라미터 데이터
  //파라미터 데이터는 NoticeManagement여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/NoticeManagementAdd?notice_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //notice_ID=2를 쓸 수 있다.
  //즉 notice_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const notice_ID_param = params.get("notice_ID");

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [noticeTitle, setNoticeTitle] = useState('');
  const [noticeFile1, setNoticeFile1] = useState('');
  const [noticeFile2, setNoticeFile2] = useState('');
  const [noticeFile3, setNoticeFile3] = useState('');
  const [noticeFile4, setNoticeFile4] = useState('');
  const [noticeFile5, setNoticeFile5] = useState('');
  const [noticeFileText1, setNoticeFileText1] = useState('');
  const [noticeFileText2, setNoticeFileText2] = useState('');
  const [noticeFileText3, setNoticeFileText3] = useState('');
  const [noticeFileText4, setNoticeFileText4] = useState('');
  const [noticeFileText5, setNoticeFileText5] = useState('');
  const [noticeStatus, setNoticeStatus] = useState('');

  //에러 출력 데이터
  const [noticeTitleError, setNoticeTitleError] = useState('');

  const editorRef = useRef(null);

  const [htmlString, setHtmlString] = useState("");
  const [initialValue, setInitialValue] = useState("");

  //컨트롤 함수
  const notice_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(noticeTitle === ''){setNoticeTitleError('공지사항 타이틀을 등록해 주세요.'); continue_add = false;}else{setNoticeTitleError('');}
    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(notice_ID_param === null){
        insert_notice(
          noticeTitle,
          noticeFile1,
          noticeFile2,
          noticeFile3,
          noticeFile4,
          noticeFile5,
          noticeFileText1,
          noticeFileText2,
          noticeFileText3,
          noticeFileText4,
          noticeFileText5,
          htmlString.replace( /'/gi, '"')
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/NoticeManagement";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_notice(
          notice_ID_param,
          noticeTitle,
          noticeFile1,
          noticeFile2,
          noticeFile3,
          noticeFile4,
          noticeFile5,
          noticeFileText1,
          noticeFileText2,
          noticeFileText3,
          noticeFileText4,
          noticeFileText5,
          noticeStatus,
          htmlString.replace( /'/gi, '"'),
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/NoticeManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  const file_size_check = (e) => {
    if(e.target.files[0] !== undefined){
      var fsize = e.target.files[0].size;
      var file = Math.round((fsize / 1024));
      if (file >= 10240) {
        return false
      } else {
        return true
      }
    }

}

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //만약에 파라미터를 보내지 않으면 notice_ID값은 null(빈값으로 오기 떄문에)
    //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
    if(notice_ID_param !== null){
      //특정 ID의 공지사항 데이터를 가져오기 위한 함수
      get_notice_one(notice_ID_param).then((result) => {
        //데이터를 가져오면 데이터를 집어넣어준다.
        setNoticeTitle(result[0].notice_title);
        setInitialValue(result[0].notice_content);
        setNoticeStatus(result[0].notice_status);
        setNoticeFileText1(result[0].notice_file_text_1);
        setNoticeFileText2(result[0].notice_file_text_2);
        setNoticeFileText3(result[0].notice_file_text_3);
        setNoticeFileText4(result[0].notice_file_text_4);
        setNoticeFileText5(result[0].notice_file_text_5);

      })
    }
  },[notice_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>게시판 관리 - 공지사항 관리 {notice_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{
                  notice_check();
                }}
              >
                {/* 공지사항 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                {notice_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='notice_title'>공지사항 타이틀<span> * </span></label>
              <input 
                type='text' 
                id = 'notice_title' 
                maxLength={100} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setNoticeTitle(event_data.target.value);
                }} 
                placeholder = "공지사항 타이틀을 입력해 주세요."
                value={noticeTitle}
              />
              <H.admin_errorBox className="marginLeft150">{noticeTitleError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelImg>
              <p className="labelImgTit">공지사항 파일</p>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                      <p>공지사항 파일1</p>
                      {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                      <label htmlFor='notice_file_1'>
                        {/* 공지사항 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                        변경
                      </label>
                      <label onClick={()=>{setNoticeFileText1('')}}>
                        삭제
                      </label>
                    </div>
                    <input 
                      className='display_none'
                      type='file' 
                      id='notice_file_1'
                      //이건 input type='file'일 경우만 가능한건데
                      //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(e)=>{
                        if(file_size_check(e)){
                          setNoticeFile1(e.target.files[0]);
                          setNoticeFileText1(e.target.files[0].name);
                        }
                      }}
                    />
                    <p>{noticeFileText1}</p>
                </div>
              </div>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                      <p>공지사항 파일2</p>
                      {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                      <label htmlFor='notice_file_2'>
                        {/* 공지사항 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                        변경
                      </label>
                      <label onClick={()=>{setNoticeFileText2('')}}>
                        삭제
                      </label>
                    </div>
                    <input 
                      className='display_none'
                      type='file' 
                      id='notice_file_2'
                      //이건 input type='file'일 경우만 가능한건데
                      //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(e)=>{
                        if(file_size_check(e)){
                          setNoticeFile2(e.target.files[0]);
                          setNoticeFileText2(e.target.files[0].name);
                        }
                      }}
                    />
                    <p>{noticeFileText2}</p>
                </div>
              </div>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                      <p>공지사항 파일3</p>
                      {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                      <label htmlFor='notice_file_3'>
                        {/* 공지사항 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                        변경
                      </label>
                      <label onClick={()=>{setNoticeFileText3('')}}>
                        삭제
                      </label>
                    </div>
                    <input 
                      className='display_none'
                      type='file' 
                      id='notice_file_3'
                      //이건 input type='file'일 경우만 가능한건데
                      //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(e)=>{
                        if(file_size_check(e)){
                          setNoticeFile3(e.target.files[0]);
                          setNoticeFileText3(e.target.files[0].name);
                        }
                      }}
                    />
                    <p>{noticeFileText3}</p>
                </div>
              </div>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                      <p>공지사항 파일4</p>
                      {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                      <label htmlFor='notice_file_4'>
                        {/* 공지사항 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                        변경
                      </label>
                      <label onClick={()=>{setNoticeFileText4('')}}>
                        삭제
                      </label>
                    </div>
                    <input 
                      className='display_none'
                      type='file' 
                      id='notice_file_4'
                      //이건 input type='file'일 경우만 가능한건데
                      //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(e)=>{
                        if(file_size_check(e)){
                          setNoticeFile4(e.target.files[0]);
                          setNoticeFileText4(e.target.files[0].name);
                        }
                      }}
                    />
                    <p>{noticeFileText4}</p>
                </div>
              </div>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                      <p>공지사항 파일5</p>
                      {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                      <label htmlFor='notice_file_5'>
                        {/* 공지사항 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                        변경
                      </label>
                      <label onClick={()=>{setNoticeFileText5('')}}>
                        삭제
                      </label>
                    </div>
                    <input 
                      className='display_none'
                      type='file' 
                      id='notice_file_5'
                      //이건 input type='file'일 경우만 가능한건데
                      //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(e)=>{
                        if(file_size_check(e)){
                          setNoticeFile5(e.target.files[0]);
                          setNoticeFileText5(e.target.files[0].name);
                        }
                      }}
                    />
                    <p>{noticeFileText5}</p>
                </div>
              </div>
            </H.admin_labelImg>

            {notice_ID_param !== null &&  
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='notice_status'>사용 여부</label>
              <select
                value={noticeStatus}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setNoticeStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
              
            </H.admin_labelBox>
            }


            <H.listInfoText className="marginTop50">
              <label htmlFor='news_event_editor' className="listInfoTextBold">
                공지사항 세부내용 사용 방법 안내<span> * </span>
              </label>
              <p>1. 보안 관계로 ''(작은따옴표)는 사용할 수 없습니다.</p>
              <p>2. ''(작은따옴표)를 사용한 경우에는 자동으로 ""(큰따옴표)로 변경됩니다.</p>
              <p>3. 이미지 파일명은 반드시 영문명으로 지정해야 오류가 나지 않습니다.</p>
              <p>4. 서식 적용이 되지 않을 경우 "서식 - 서식지우기" 아이콘을 활용하여 서식을 초기화 한 후 작성해주세요.</p>
              <p>5. 이미지 등록 시 PC는 최대 가로길이 1200px로 맞춰야 이미지 잘림 없이 볼 수 있습니다.</p>
            </H.listInfoText>
            <H.admin_editorBoxTit>
              공지사항 내용
            </H.admin_editorBoxTit>
            <Editor
              //tiny를 쓰기위한 고유 apiKey 전송
              apiKey='2c69ik81krso3r31bw1hgusvsc73bbxoth4z4rbfvskj9p9y'
              //처음 실행 시 위 선언했던 useRef를 참고함
              onInit={(evt, editor) => editorRef.current = editor}
              //초기 HTML 적용
              initialValue={initialValue}
              //init은 Varibale.js에서 가져왔으며 폰트추가나 플러그인 툴바 등등 에디터에 관한
              //내용을 추가 시키고 있으면 저기서 추가하면 됨
              init={editor_init_data}
              onEditorChange={(newValue, editor) => {
                setHtmlString(newValue);
              }}
            />
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default NoticeManagementAdd;
